<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Create Vesting Schedule'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Create Vesting Schedule</span>
      </v-card-title>
      <common-trs-card class="mx-6 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="pa-0">
            <form>
              <vesting-schedule-form
                v-model="vestingScheduleData"
                @update="update"
              />
            </form>
          </v-card-text>
          <v-row class="mt-5 mx-2">
            <v-col>
              <v-divider />
              <div class="d-flex justify-space-between mt-3">
                <common-trs-btn
                  type="secondary"
                  medium
                  class="pull-right mr-2"
                  text
                  :to="{name: 'ManageSecurities'}"
                >
                  Cancel
                </common-trs-btn>
                <common-trs-btn
                  type="primary"
                  medium
                  class="white--text pull-right"
                  text
                  :disabled="!valid"
                  :loading="loading"
                  @click="save"
                >
                  Save
                </common-trs-btn>
              </div>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import VestingScheduleForm from '@/components/common/captable/components/forms/VestingScheduleForm'

  export default {
    name: 'CreateVstingSchedule',

    components: {
      VestingScheduleForm,
    },

    data: () => ({
      loading: false,
      vestingScheduleData: {},
      apiData: '',
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    methods: {
      makeApiData () {
        if (this.vestingScheduleData.custom && this.vestingScheduleData.custom.length > 0) {
          const result = this.vestingScheduleData.custom.map(item => {
            return { ...item, percent: item.percent / 100 }
          })
          this.vestingScheduleData.custom = result
          this.apiData = {
            vesting_schedule: this.vestingScheduleData,
          }
        } else {
          // Time based
          this.apiData = this.makeApiDataForCliff()
        }
        return this.apiData
      },

      makeApiDataForCliff () {
        if (this.vestingScheduleData.time_based && this.vestingScheduleData.time_based.cliff) {
          // Has cliff
          return {
            vesting_schedule: {
              ...this.vestingScheduleData,
              immediate_vest_percent: this.vestingScheduleData.immediate_vest_percent / 100,
              time_based: {
                ...this.vestingScheduleData.time_based,
                cliff: {
                  ...this.vestingScheduleData.time_based.cliff,
                  percent: this.vestingScheduleData.time_based.cliff.percent / 100,
                },
              },
            },
          }
        } else {
          // No cliff
          return {
            vesting_schedule: {
              ...this.vestingScheduleData,
              immediate_vest_percent: this.vestingScheduleData.immediate_vest_percent / 100,
            },
          }
        }
      },

      async save () {
        this.loading = true
        // Custom
        const apiData = this.makeApiData()
        try {
          await captableService.draftVestingSchedule(this.captableId, apiData)
          this.$store.dispatch('app/message', { text: 'Vesting schedule saved successfully.' })
          this.loading = false
          this.$router.push({ name: 'ManageSecurities' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: err.response.data.errors[0].detail,
          })
        }
      },

      update (val) {
        this.vestingScheduleData = val
      },
      close () {
        this.$router.push({ name: 'ManageSecurities' })
      },
    },
  }
</script>
