<template>
  <v-label
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-label>
</template>

<script>
  export default {
    name: 'FormTemplatesInputLabel',

    props: {
    },
  }
</script>
<style lang="scss" scoped>
  .v-label {
    color: rgba(0, 0, 0);
    padding-bottom: 10px;
    display: block;
  }
</style>
