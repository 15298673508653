<template>
  <div>
    <input-label
      v-if="$formTemplInputLongLabel(inputTempl)"
      class="mb-1"
      v-text="$formTemplInputLongLabel(inputTempl)"
    />
    <v-menu
      v-if="mode !== 'view'"
      ref="showPicker"
      v-model="showPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider
          ref="validationProvider"
          v-slot="{ errors }"
          :rules="ruleSets"
          :name="inputTempl.label"
          :vid="inputTempl.id"
        >
          <common-input-text-field
            v-model="internalValue"
            v-mask="mask"
            :label="$formTemplInputShortLabel(inputTempl)"
            v-bind="attrs"
            :disabled="mode === 'view'"
            :persistent-hint="mode !== 'view'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :clearable="clearable"
            v-on="on"
            @blur="date = parseDate(internalValue)"
          >
            <template v-slot:append>
              <v-icon
                v-on="on"
              >
                mdi-calendar
              </v-icon>
            </template>
          </common-input-text-field>
        </validation-provider>
      </template>
      <common-input-date-picker
        v-model="date"
        no-title
        color="primary"
        @input="showPicker = false"
        @change="onDatePicked"
      />
    </v-menu>
    <div
      v-if="mode !== 'view'"
      :class="mode !== 'noComments' ? 'input-template-comment mt-2' : 'input-template-comment'"
      v-html="inputTempl.comment"
    />
    <v-row
      v-else
      dense
      class="form-template-view-input-row"
    >
      <v-col cols="6">
        <span class="form-template-view-input-label">
          {{ `${inputTempl.label}${inputTempl.mandatory ? '*' :''}` }}
        </span>
      </v-col>
      <v-col cols="6">
        {{ internalValue || '-' }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import InputLabel from './InputLabel'
  import moment from 'moment'
  export default {
    name: 'InputTemplateText',

    components: {
      InputLabel,
    },

    props: {
      value: {
        validate: () => true,
        required: true,
      },
      inputTempl: {
        type: Object,
      },
      sectionValues: {
        type: Object,
      },
      mode: {
        type: String,
      },
      useDotPropAccess: {
        type: Boolean,
      },
      clearable: {
        type: Boolean,
      },
      mask: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      // internalValue: '',
      showPicker: false,
      date: new Date().toISOString().substr(0, 10),
    }),

    computed: {
      ruleSets () {
        const ruleSets = {}
        if (this.inputTempl.mandatory) {
          ruleSets.required = true
        }
        ruleSets.isDateStandard = true
        return { ...ruleSets, ...this.inputTempl.customRuleSets }
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      date (val) {
        this.internalValue = this.formatDate(this.date) || ''
      },
    },

    methods: {
      onDatePicked () {
        if (this.$refs.validationProvider) {
          this.$refs.validationProvider.setFlags({ dirty: true })
        }
      },
      formatDate (date) {
        if (!date) return null

        const formatted = moment(date)
        if (!formatted.isValid()) return null

        return formatted.format('MM/DD/YYYY')
      },
      parseDate (date) {
        if (!date) return null

        const formatted = moment(date)
        if (!formatted.isValid()) return null

        return formatted.format('YYYY-MM-DD')
      },
    },
  }
</script>
