var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form"},[(!_vm.hideName)?_c('v-row',{staticClass:"ma-0 mt-4"},[_c('v-col',{attrs:{"cols":"6","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Vesting Schedule name',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Vesting Schedule name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.name),callback:function ($$v) {_vm.$set(_vm.internalValue, "name", $$v)},expression:"internalValue.name"}})]}}],null,false,764008976)})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_vm._v(" Vesting Type ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Vesting Type',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"checked":""},on:{"change":_vm.update},model:{value:(_vm.vestingType),callback:function ($$v) {_vm.vestingType=$$v},expression:"vestingType"}},_vm._l((_vm.CONSTS.VESTING_TYPES),function(item,key){return _c('v-radio',{key:key,attrs:{"label":item.title,"color":"primary","value":item.value}})}),1)]}}])})],1)],1),(_vm.vestingType === _vm.CONSTS.VESTING_TYPE.CUSTOM)?_c('div',_vm._l((_vm.customData),function(item,key){return _c('v-row',{key:key,staticClass:"pl-4 py-0 mt-0"},[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"6"}},[_c('validation-provider',{attrs:{"name":'Equity percent',"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Equity","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"append-icon":"mdi-percent-outline"},model:{value:(item.percent),callback:function ($$v) {_vm.$set(item, "percent", $$v)},expression:"item.percent"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"6"}},[_c('validation-provider',{attrs:{"name":'Vesting date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Date', mandatory: true},"error-messages":errors,"hide-details":errors.length === 0,"clearable":""},on:{"input":function($event){return _vm.updateDate(item)}},model:{value:(item.vestingDate),callback:function ($$v) {_vm.$set(item, "vestingDate", $$v)},expression:"item.vestingDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6"}},[_c('validation-provider',{attrs:{"name":'Event name',"rules":{ required: false, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Event name (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(item.reason),callback:function ($$v) {_vm.$set(item, "reason", $$v)},expression:"item.reason"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"2"}},[_c('common-trs-btn',{attrs:{"type":"tertiary","icon":""},on:{"click":function($event){return _vm.addCustom(true)}}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1),_c('common-trs-btn',{staticClass:"delete-btn",attrs:{"type":"tertiary","icon":"","disabled":_vm.customData.length===1},on:{"click":_vm.removeCustom}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),1):_vm._e(),(_vm.vestingType===_vm.CONSTS.VESTING_TYPE.TIME_BASED)?_c('div',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Share term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Vest after","placeholder":"Select vest after","items":_vm.CONSTS.VESTING_PERIOD_TYPE_SELECT,"item-text":"text","item-value":"value","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.time_based.vesting_period),callback:function ($$v) {_vm.$set(_vm.internalValue.time_based, "vesting_period", $$v)},expression:"internalValue.time_based.vesting_period"}})]}}],null,false,717201548)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'No. of months',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"No. of months","color":"primary","type":"number","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.time_based.vesting_term),callback:function ($$v) {_vm.$set(_vm.internalValue.time_based, "vesting_term", $$v)},expression:"internalValue.time_based.vesting_term"}})]}}],null,false,4069288217)})],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Immediate vesting percentage',"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Immediate vesting (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"append-icon":"mdi-percent-outline"},model:{value:(_vm.internalValue.immediate_vest_percent),callback:function ($$v) {_vm.$set(_vm.internalValue, "immediate_vest_percent", $$v)},expression:"internalValue.immediate_vest_percent"}})]}}],null,false,202298558)})],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_vm._v(" Cliff Vesting ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Cliff vesting',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"column":"","error-messages":errors},on:{"change":_vm.updateCliff},model:{value:(_vm.cliffVesting),callback:function ($$v) {_vm.cliffVesting=$$v},expression:"cliffVesting"}},_vm._l((_vm.CONSTS.YES_NO_RADIO),function(item,key){return _c('v-radio',{key:key,attrs:{"label":item.title,"color":"primary","value":item.title}})}),1)]}}],null,false,27045357)})],1)],1),(_vm.cliffVesting === _vm.CONSTS.CLIFF_VESTING_TYPES.YES)?_c('v-row',{staticClass:"ma-0"},[_c('v-col',[_c('validation-provider',{attrs:{"name":'Equity percentage',"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Equity","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"append-icon":"mdi-percent-outline"},model:{value:(_vm.internalValue.time_based.cliff.percent),callback:function ($$v) {_vm.$set(_vm.internalValue.time_based.cliff, "percent", $$v)},expression:"internalValue.time_based.cliff.percent"}})]}}],null,false,3419581097)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":'No. of months',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"type":"number","label":"No. of months","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.time_based.cliff.duration),callback:function ($$v) {_vm.$set(_vm.internalValue.time_based.cliff, "duration", $$v)},expression:"internalValue.time_based.cliff.duration"}})]}}],null,false,2682500266)})],1)],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }