<template>
  <div>
    <v-form ref="form">
      <v-row
        v-if="!hideName"
        class="ma-0 mt-4"
      >
        <v-col
          cols="6"
          lg="6"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Vesting Schedule name'"
            :rules="{ required: true, max: 255 }"
          >
            <common-input-text-field
              v-model="internalValue.name"
              label="Vesting Schedule name"
              color="primary"
              :error-messages="errors"
              :hide-details="errors.length === 0"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col
          cols="12"
          class="py-0"
        >
          Vesting Type
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          lg="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'Vesting Type'"
            :rules="{ required: true }"
          >
            <v-radio-group
              v-model="vestingType"
              :error-messages="errors"
              :hide-details="errors.length === 0"
              checked
              @change="update"
            >
              <v-radio
                v-for="(item, key) of CONSTS.VESTING_TYPES"
                :key="key"
                :label="item.title"
                color="primary"
                :value="item.value"
              />
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>
      <!-- custom  -->
      <div v-if="vestingType === CONSTS.VESTING_TYPE.CUSTOM">
        <v-row
          v-for="(item,key) in customData"
          :key="key"
          class="pl-4 py-0 mt-0"
        >
          <v-col
            cols="12"
            lg="3"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="'Equity percent'"
              :rules="{ required: true, max: 100 }"
            >
              <common-input-currency-input
                v-model="item.percent"
                label="Equity"
                color="primary"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :options="{ locale: 'en-US', precision: 2 }"
                append-icon="mdi-percent-outline"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="'Vesting date'"
              :rules="{ required: true }"
            >
              <input-template-date
                v-model="item.vestingDate"
                :mask="'##/##/####'"
                :input-templ="{label: 'Date', mandatory: true}"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                clearable
                @input="updateDate(item)"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="'Event name'"
              :rules="{ required: false, max: 255 }"
            >
              <common-input-text-field
                v-model="item.reason"
                label="Event name (Optional)"
                color="primary"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="2"
            class="mt-4"
          >
            <common-trs-btn
              type="tertiary"
              icon
              @click="addCustom(true)"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </common-trs-btn>
            <common-trs-btn
              class="delete-btn"
              type="tertiary"
              icon
              :disabled="customData.length===1"
              @click="removeCustom"
            >
              <v-icon>mdi-delete</v-icon>
            </common-trs-btn>
          </v-col>
        </v-row>
      </div>
      <!-- custom end -->
      <div v-if="vestingType===CONSTS.VESTING_TYPE.TIME_BASED">
        <v-row
          class="ma-0"
        >
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="'Share term'"
              :rules="{ required: true }"
            >
              <common-input-select
                v-model="internalValue.time_based.vesting_period"
                label="Vest after"
                placeholder="Select vest after"
                :items="CONSTS.VESTING_PERIOD_TYPE_SELECT"
                item-text="text"
                item-value="value"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="'No. of months'"
              :rules="{ required: true, max: 255 }"
            >
              <common-input-text-field
                v-model="internalValue.time_based.vesting_term"
                label="No. of months"
                color="primary"
                type="number"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row
          class="ma-0"
        >
          <v-col
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="'Immediate vesting percentage'"
              :rules="{ max: 100 }"
            >
              <common-input-currency-input
                v-model="internalValue.immediate_vest_percent"
                label="Immediate vesting (Optional)"
                color="primary"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :options="{ locale: 'en-US', precision: 2 }"
                append-icon="mdi-percent-outline"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row
          class="ma-0"
        >
          <v-col
            cols="12"
            class="py-0"
          >
            Cliff Vesting
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            lg="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="'Cliff vesting'"
              :rules="{ required: true }"
            >
              <v-radio-group
                v-model="cliffVesting"
                column
                :error-messages="errors"
                @change="updateCliff"
              >
                <v-radio
                  v-for="(item, key) of CONSTS.YES_NO_RADIO"
                  :key="key"
                  :label="item.title"
                  color="primary"
                  :value="item.title"
                />
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row
          v-if="cliffVesting === CONSTS.CLIFF_VESTING_TYPES.YES"
          class="ma-0"
        >
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              :name="'Equity percentage'"
              :rules="{ required: true, max: 100 }"
            >
              <common-input-currency-input
                v-model="internalValue.time_based.cliff.percent"
                label="Equity"
                color="primary"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :options="{ locale: 'en-US', precision: 2 }"
                append-icon="mdi-percent-outline"
              />
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              :name="'No. of months'"
              :rules="{ required: true, max: 255 }"
            >
              <common-input-text-field
                v-model="internalValue.time_based.cliff.duration"
                type="number"
                label="No. of months"
                color="primary"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'

  export default {
    name: 'VestingScheduleForm',

    components: {
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        required: true,
      },
      hideName: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
      },
    },

    data: () => ({
      datePickerAttr: { max: new Date().toISOString() },
      vestingType: '',
      cliffVesting: 'No',
      showActiveForm: false,
      customData: [],
    }),

    computed: {
      internalValue: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            if (val.custom && val.custom.length > 0) {
              this.vestingType = this.CONSTS.VESTING_TYPE.CUSTOM
              val.custom.forEach((item) => {
                item.vestingDate = moment(item.vests_on).format('MM/DD/YYYY')
                item.percent = item.percent * 100
              })
            }
            if (val.time_based && Object.keys(val.time_based) && Object.keys(val.time_based).length > 0) {
              this.vestingType = this.CONSTS.VESTING_TYPE.TIME_BASED
              this.internalValue.immediate_vest_percent = val.immediate_vest_percent * 100
              if (val.time_based && val.time_based.cliff) {
                this.cliffVesting = this.CONSTS.CLIFF_VESTING_TYPES.YES
                this.internalValue.time_based.cliff.percent = val.time_based.cliff.percent * 100
              } else {
                delete val.time_based.cliff
              }
            }
            this.customData = this.internalValue.custom ? this.internalValue.custom : []
            this.internalValue.time_based = this.internalValue.time_based ? this.internalValue.time_based : {}
            this.$emit('input', this.internalValue)
          }
        },
        immediate: true,
      },
      hideName: {
        handler (val) {
          if (val) {
            this.internalValue.name = 'Custom vesting'
          }
        },
        immediate: true,
      },
    },

    methods: {
      addCustom (add, data) {
        const customDatObj = {
          percent: '',
          vests_on: '',
          reason: '',
        }
        if (this.customData.length !== 0 && add) {
          this.customData.push(customDatObj)
        } else if (!add) {
          if (data.length === 0) {
            this.customData.push(customDatObj)
          } else {
            this.customData = data
          }
        }
        this.internalValue.custom = this.customData
        this.$emit('input', this.internalValue)
      },

      removeCustom () {
        this.customData.pop()
      },

      updateCliff () {
        if (this.cliffVesting === this.CONSTS.CLIFF_VESTING_TYPES.YES) {
          this.internalValue.time_based = { ...this.internalValue.time_based, cliff: {} }
        } else {
          delete this.internalValue.time_based.cliff
        }
      },
      update () {
        this.$emit('updateType', true)
        if (this.vestingType === this.CONSTS.VESTING_TYPE.CUSTOM) {
          // custom
          if (!this.isEdit) {
            delete this.internalValue.time_based
            delete this.internalValue.immediate_vest_percent
          }

          this.addCustom(false, this.customData)
        } else {
          if (!this.internalValue.time_based) {
            this.internalValue.time_based = {}
          } else {
            this.internalValue.time_based = { ...this.internalValue.time_based }
          }
          // time based
          delete this.internalValue.custom
          // this.removeCustom()
          this.$emit('input', this.internalValue)
        }
      },
      updateDate (item) {
        item.vests_on = moment(item.vestingDate).format('YYYY-MM-DD')
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-input--radio-group__input {
    flex-direction: row !important;
  }
  ::v-deep .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0 !important;
    margin-right: 20px;
  }
  .delete-btn {
    background: none !important;
    border: none !important; }  ::v-deep .trs-disabled-btn.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: none !important;
  }
</style>
